import React from "react";
import CustomFields from "./NonLlmCustomfields";

const AdvancedSection = ({ preferences, currentTag, setCurrentTag }) => {
  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold text-grey mb-4">Model Selection</h2>
      <p className="text-gray-600">
        Select the model(s) to be used for this tag:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {preferences.profile.OPENAI_ENDPOINTS.map((endpoint, index) => (
          <div
            key={index}
            className={`relative p-4 border rounded-lg transition-all duration-200 ${
              endpoint.enabled
                ? Array.isArray(currentTag.selected_tag_model) &&
                  currentTag.selected_tag_model.includes(endpoint.model_used)
                  ? "cursor-pointer border-primary bg-primary bg-opacity-10 text-white"
                  : "cursor-pointer border-gray-200 hover:border-primary hover:shadow-md"
                : "border-gray-300 bg-gray-100 text-gray-400 cursor-not-allowed"
            }`}
            onClick={() => {
              if (endpoint.enabled) {
                setCurrentTag((prevTag) => {
                  const selectedModels = Array.isArray(
                    prevTag.selected_tag_model,
                  )
                    ? prevTag.selected_tag_model
                    : [];
                  const isSelected = selectedModels.includes(
                    endpoint.model_used,
                  );
                  const newSelectedTagModel = isSelected
                    ? selectedModels.filter(
                        (model) => model !== endpoint.model_used,
                      )
                    : [...selectedModels, endpoint.model_used];
                  return {
                    ...prevTag,
                    selected_tag_model: newSelectedTagModel,
                  };
                });
              }
            }}
          >
            <h3
              className={`font-semibold mb-2 ${!endpoint.enabled && "text-gray-400"}`}
            >
              {endpoint.model_used}
            </h3>
            <p className="text-sm text-gray-600">
              Provider: {endpoint.api_type}
            </p>
            {!endpoint.enabled && (
              <div className="absolute inset-0 flex items-center justify-center group">
                <span className="absolute left-1/2 transform -translate-x-1/2 -translate-y-full px-4 py-2 text-xs text-red-600 bg-white border border-red-600 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  This model has been disabled in Preferences.
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
      {Array.isArray(currentTag.selected_tag_model) &&
        currentTag.selected_tag_model.length > 0 && (
          <p className="mt-4 text-primary">
            Selected model(s): {currentTag.selected_tag_model.join(", ")}
          </p>
        )}
      <CustomFields
        customFields={currentTag.customFields}
        setCustomFields={(newCustomFields) => {
          setCurrentTag((prevTag) => ({
            ...prevTag,
            customFields: newCustomFields,
          }));
        }}
        currentTag={currentTag}
      />
    </div>
  );
};

export default AdvancedSection;
