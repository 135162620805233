import { useCallback } from 'react';
import ConfirmationModal from '../components/utilities/ConfirmationModal';

const useConfirm = () => {
  const confirm = useCallback((title, message) => {
    return new Promise((resolve) => {
      ConfirmationModal.show({
        title,
        message,
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }, []);

  return { confirm };
};

export default useConfirm;