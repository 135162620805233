import React, { useState, useEffect, useContext, useMemo } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { useCatalogDocumentInfoLoader } from "../../../../../../api/queryHooks";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { API_USERNAME_KEYWORD } from "../../../../../../constants/fixedValues";
import Auth from "../../../../../../auth/AuthProvider";

const IMAGE_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".webp",
  ".tiff",
];

const ImageSelector = ({ onSelect, onClose }) => {
  const { catalogFiles, setShowConnectData, setView } = useContext(DataContext);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState([]);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        if (!catalogFiles || Object.keys(catalogFiles).length === 0) {
          console.log("catalogFiles is empty or undefined");
          setLoading(false);
          return;
        }

        const filteredImages = Object.entries(catalogFiles)
          .filter(([filename]) =>
            IMAGE_EXTENSIONS.some((ext) =>
              filename.toLowerCase().endsWith(ext),
            ),
          )
          .map(([filename, fileData]) => ({
            filename,
            storage: fileData.storage_type[0],
            path: fileData.file_directory[0],
          }));

        const imagePromises = filteredImages.map(async (image) => {
          try {
            const documentInfo = await loadDocumentInfo(image.filename);
            const url = documentInfo.file_url.toString();
            console.log(`Loaded URL for ${image.filename}:`, url);
            return { ...image, url };
          } catch (err) {
            console.error(`Error loading info for ${image.filename}:`, err);
            return null;
          }
        });

        const resolvedImages = (await Promise.all(imagePromises)).filter(
          Boolean,
        );
        setImages(resolvedImages);
      } catch (err) {
        console.error("Error fetching image URLs:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchImageUrls();
  }, [catalogFiles, loadDocumentInfo]);

  const filteredImages = useMemo(() => {
    return images.filter((image) =>
      image.filename.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [images, searchTerm]);

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const handleImagePreview = (image) => {
    setLightboxImage([{ src: image.url }]);
    setIsLightboxOpen(true);
  };

  const handleConfirmSelection = async () => {
    if (selectedImage) {
      // Construct the image information object
      const imageData = {
        filename: selectedImage.filename,
        path: selectedImage.path,
        storage: selectedImage.storage,
        [API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser())
          .username,
      };
      console.log("Image data to send:", imageData);
      onSelect(imageData);
      onClose();
    }
  };

  if (loading) return <div>Loading images...</div>;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-3xl max-h-[80vh] overflow-y-auto">
          <h3 className="text-lg font-medium text-deasieBlack mb-4">
            Select an Image
          </h3>
          
          {/* Add search bar */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search images..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-[rgba(40,165,144,1)] focus:border-[rgba(40,165,144,1)]"
            />
          </div>

          {filteredImages.length === 0 ? (
            <div className="flex flex-col items-center">
              <p>No images found in the catalog.</p>

              <div className="flex row gap-2">
                <button
                  onClick={onClose}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-md transition duration-300 mt-4"
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    onClose();
                    setShowConnectData(true);
                    setView("options");
                  }}
                  className="mt-4 bg-primary hover:bg-secondary text-white font-medium py-2 px-4 rounded-md transition duration-300"
                >
                  Add Images
                </button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-4 mb-4">
              {filteredImages.map((image, index) => (
                <div
                  key={index}
                  className={`relative cursor-pointer ${
                    selectedImage?.filename === image.filename
                      ? "ring-2 ring-primary"
                      : ""
                  }`}
                  onClick={() => handleImageSelect(image)}
                >
                  <img
                    src={`${image.url}&timestamp=${new Date().getTime()}`}
                    alt={image.filename}
                    className="w-full h-40 object-cover hover:opacity-75 transition duration-300"
                    onError={(e) => {
                      console.error("Image failed to load:", image.filename);
                      e.target.style.opacity = "0.3";
                    }}
                    onDoubleClick={() => handleImagePreview(image)}
                  />
                  <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs p-1 truncate">
                    {image.filename}
                  </p>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-end">
            {filteredImages.length > 0 && (
              <>
                <button
                  onClick={onClose}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-md transition duration-300"
                >
                  Close
                </button>
                <button
                  onClick={handleConfirmSelection}
                  disabled={!selectedImage}
                  className="bg-[rgba(40,165,144,1)] hover:bg-[#1a735e] text-white font-medium py-2 px-4 rounded-md ml-2 transition duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
                >
                  Confirm Selection
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {isLightboxOpen && (
        <Lightbox
          open={isLightboxOpen}
          close={() => setIsLightboxOpen(false)}
          slides={lightboxImage}
        />
      )}
    </>
  );
};

export default ImageSelector;
