import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomFields = ({ currentTag, customFields, setCustomFields }) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (customFields && Object.keys(customFields).length > 0) {
      const initialFields = Object.entries(customFields).map(
        ([key, value]) => ({
          key,
          value,
        }),
      );
      setFields(initialFields);
    } else {
      setFields([]);
    }
  }, [currentTag.name]);

  const addNewField = () => {
    const newFields = [...fields, { key: "", value: "" }];
    setFields(newFields);
    updateCustomFields(newFields);
  };

  const updateField = (index, key, value) => {
    const newFields = [...fields];
    newFields[index] = { key, value };
    setFields(newFields);
    updateCustomFields(newFields);
  };

  const removeField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    updateCustomFields(newFields);
  };

  const updateCustomFields = (newFields) => {
    const updatedCustomFields = newFields.reduce((acc, field) => {
      if (field.key && field.value) {
        acc[field.key] = field.value;
      }
      return acc;
    }, {});

    setCustomFields(updatedCustomFields);
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold text-grey mb-4">Custom Fields</h2>
      <p className="text-gray-600 mb-4">
        Add custom key-value pairs to your tag:
      </p>
      {fields.map((field, index) => (
        <div key={index} className="flex mb-4">
          <input
            type="text"
            className="flex-1 p-2 border rounded-l-md"
            placeholder="Key"
            value={field.key}
            onChange={(e) => updateField(index, e.target.value, field.value)}
          />
          <input
            type="text"
            className="flex-1 p-2 border-t border-b"
            placeholder="Value"
            value={field.value}
            onChange={(e) => updateField(index, field.key, e.target.value)}
          />
          <button
            onClick={() => removeField(index)}
            className="bg-red-500 text-white p-2"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      ))}
      <button
        onClick={addNewField}
        className="bg-primary text-white p-2 rounded-md"
      >
        <FontAwesomeIcon icon={faPlus} /> Add New Field
      </button>
    </div>
  );
};

export default CustomFields;
