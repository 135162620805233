import React from 'react';
import ReactDOM from 'react-dom';

const ConfirmationModal = ({ title, message, onConfirm, onCancel }) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

ConfirmationModal.show = ({ title, message, onConfirm, onCancel }) => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const close = () => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };

  ReactDOM.render(
    <ConfirmationModal
      title={title}
      message={message}
      onConfirm={() => {
        close();
        onConfirm();
      }}
      onCancel={() => {
        close();
        onCancel();
      }}
    />,
    container
  );
};

export default ConfirmationModal;