import { useState } from "react";

const InfoIcon = ({ infoText }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        className="text-buttonGrey border border-buttonGrey font-bold rounded-full w-4 h-4 flex items-center justify-center cursor-pointer "
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        ?
      </div>
      {showInfo && (
        <div className="absolute top-8 left-[10vw] transform -translate-x-1/2 bg-buttonGrey text-white p-2 border border-gray-300 shadow-lg rounded w-72 z-50">
          <span className="text-sm" dangerouslySetInnerHTML={{ __html: infoText }} />
        </div>
      )}
    </div>
  );
};

export default InfoIcon;
