function SimpleModal({ isOpen, onClose, message }) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-1/3">
        <p
          className="text-lg"
          dangerouslySetInnerHTML={{ __html: message }}
        ></p>
        <button
          className="mt-4 bg-primary hover:bg-deasieTurquoise text-white font-bold py-2 px-4 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default SimpleModal;
