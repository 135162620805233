import { useContext } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";

export default function TabComponent({
  activeTab,
  setActiveTab,
  handleFileChange,
  fileInputRef = null,
}) {
  const { currentTag, availableTags } = useContext(DataContext);
  const { setIsTagLibraryCollapsed } = useContext(TagContext);

  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
  };

  const tabs = [
    {
      label: "User-defined tags",
      action: (index) => handleManuallyDefineTag(index),
    },
    {
      label: "Test & refine tags",
      action: (index) => handleTestRefineTags(index),
    },
    {
      label: "Auto-generate tags",
      action: (index) => handleAutoGenerateTags(index),
    },
    {
      label: "Create tag rules",
      action: (index) => handleCreateTagRules(index),
    },
    {
      label: "Import tags",
      action: (index) => handleImportTags(index),
    },
  ];

  const handleManuallyDefineTag = (index) => {
    setActiveTab(index);
  };

  const handleAutoGenerateTags = (index) => {
    // setIsTagLibraryCollapsed(true);
    setActiveTab(index);
  };

  const handleTestRefineTags = (index) => {
    // setIsTagLibraryCollapsed(true);
    setActiveTab(index);
  };

  const handleCreateTagRules = (index) => {
    // setIsTagLibraryCollapsed(true);
    setActiveTab(index);
  };

  const handleImportTags = (index) => {
    // setIsTagLibraryCollapsed(true);
    setActiveTab(index);
  };

  return (
    <div className="w-full flex bg-gray-200 flex-row justify-between">
      {tabs.map((tab, index) => {
        let isDisabled = false;
        let tabTitle = "";

        if (
          index === 1 &&
          tagDict &&
          !Object.keys(tagDict).includes(currentTag.name)
        ) {
          isDisabled = true;
          tabTitle = "Please select or save a tag to test it";
        } else if (isDisabled) {
          tabTitle = "Feature not available in sandbox";
        }

        return (
          <div
            key={index}
            className={`border-gray-400 w-full px-4 py-2 cursor-pointer hover:bg-gray-300 duration-300 translate-all hover:text-grey
              ${
                activeTab === index
                  ? "text-bold bg-primary text-white"
                  : "bg-gray-200 text-gray-600"
              }
              ${index !== tabs.length - 1 ? "border-r" : ""}
              `}
            onClick={() => {
              tab.action(index);
            }}
            title={tabTitle}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
}
