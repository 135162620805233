import "./DataCatalog.css";
import TagFilter from "../../../../utilities/TagFilter/TagFilter";
import { toast } from "./../../../../utilities/Toast";
import {
  useContext,
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  runningTasksAtom,
  selectedTagKeysAtom,
  selectedCatalogItemsAtom,
  documentTaggingTaskAtom,
} from "../../../../../atoms";
import { useAtom } from "jotai";
import { debounce } from "lodash";
import { waitTaskDone, abortTask } from "../../../../../utils/workers";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { sendRequest } from "../../../../utilities/functions/api";
import Auth from "../../../../../auth/AuthProvider";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";
import { TagRuleFilter } from "./DataCatalogComponents/TagRuleFilter/TagRuleFilter";
import { useTagRules } from "../../../../../api/queryHooks";
import ScheduleSettingComponent from "./DataCatalogComponents/Scheduling/ScheduleSettingComponent";
import Select from "react-select";
import CoreTagsModal from "./CoreTagsSelection";

const sortOptions = [
  { value: "date-desc", label: "Date (Newest)" },
  { value: "date-asc", label: "Date (Oldest)" },
  { value: "completion-desc", label: "Completion (Highest)" },
  { value: "completion-asc", label: "Completion (Lowest)" },
  { value: "rule-desc", label: "Tag Rules" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: "#aaa",
    padding: "2px",
    height: "28px",
    minHeight: "28px",
    fontSize: "0.6375rem",
    boxShadow: "none",
    borderRadius: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      outline: "none",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "2px",
    fontSize: "0.6375rem",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px",
    maxWidth: "100%",
    overflow: "hidden",
  }),
  input: (base) => ({
    ...base,
    margin: "0",
    padding: "0",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  menu: (base) => ({
    ...base,
    width: "180px",
    right: 0,
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "2px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "180px",
    padding: "0",
    overflowY: "auto",
    overflowX: "hidden",
    fontSize: "11px",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "#28a490"
      : state.isFocused
        ? "#e0f7f4"
        : "#ffffff",
    color: state.isSelected ? "white" : "black",
    padding: "6px 8px",
    fontSize: "11px",
    margin: "2px 4px",
    borderRadius: "2px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: state.isFocused ? "#d1f0ee" : "#ffffff",
      color: state.isFocused ? "#28a490" : "black",
    },
  }),
};

const TagDefinitions = ({ isExpanded, setIsExpanded }) => {
  const {
    deleteAllLabels,
    clearLabels,
    showScreen,
    availableTags,
    preferences,
    tagsToBeDeleted,
    ruleDict,
    currentDataGroup,
    usedCatalog,
    setShowFilePreview,
    catalogGetsRenamed,
    setIsGloballyExpanded,
    getCatalogAndUpdateCatalog,
  } = useContext(DataContext);

  const { data: allTagRules = [] } = useTagRules(usedCatalog);
  const {
    relatedInfo,
    setProcessingTags,
    processingTags,
    setProcessingTagTasks,
    autoStandardizeTagsWithoutAvailableValues,
    setProcessingTagTaskProgress,
  } = useContext(TagContext);
  const [showTagRulesModal, setShowTagRulesModal] = useState(false);
  const [selectedTagKeys, setSelectedTagKeys] = useAtom(selectedTagKeysAtom);
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const [runningTasks, setRunningTasks] = useAtom(runningTasksAtom);
  const [isTimeSelectionModalOpen, setTimeSelectionModalOpen] = useState(false);
  const [searchTerm, handleSearchChange] = useState("");
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredFolderKeys, setFilteredFolderKeys] = useState([]);
  const [allSelectedTagsValid, setAllSelectedTagsValid] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [documentTaggingTask, setDocumentTaggingTask] = useAtom(
    documentTaggingTaskAtom,
  );
  const [sortMethod, setSortMethod] = useState("date");
  const [sortAscending, setSortAscending] = useState(false);
  const [isSmartSelectEnabled, setIsSmartSelectEnabled] = useState(false);
  const filteredTagRules = useMemo(
    () => allTagRules.filter((rule) => selectedTagKeys.includes(rule.tagName)),
    [allTagRules, selectedTagKeys],
  );

  const getCompletionPercentage = useCallback(
    (tagKey) => {
      const info = relatedInfo[tagKey];
      if (!info) return 0;
      const total = Object.keys(currentDataGroup).length;
      return total > 0 ? ((total - info.counter) / total) * 100 : 0;
    },
    [relatedInfo, currentDataGroup],
  );

  const tagsShown = useMemo(() => {
    return Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    }).filter((key) => {
      const tag =
        availableTags.llm.tagger_params.tag_dict[key] || ruleDict[key];
      const groupMatch = selectedGroup
        ? tag.group === selectedGroup.value
        : true;
      return (
        groupMatch &&
        (searchTerm
          ? key.toLowerCase().includes(searchTerm.toLowerCase())
          : key !== "file_directory" &&
            !Object.keys(preferences.system.SENSITIVITY_TAGS).includes(key) &&
            !preferences.system.EXCLUDE_TAGS.includes(key))
      );
    });
  }, [
    availableTags.llm.tagger_params.tag_dict,
    ruleDict,
    searchTerm,
    selectedGroup,
    preferences.system.SENSITIVITY_TAGS,
    preferences.system.EXCLUDE_TAGS,
  ]);

  const completionPercentages = useMemo(() => {
    const percentages = {};
    tagsShown.forEach((tagKey) => {
      percentages[tagKey] = getCompletionPercentage(tagKey);
    });
    return percentages;
  }, [tagsShown, getCompletionPercentage]);

  const allGroups = useMemo(() => {
    const allTags = {
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
    };
    const groups = [
      ...new Set(
        Object.values(allTags)
          .map((tag) => tag.group)
          .filter(Boolean),
      ),
    ];

    return groups
      .sort((a, b) => a.localeCompare(b))
      .map((group) => ({
        value: group,
        label: group,
      }));
  }, [availableTags]);

  const isRuleTag = useCallback(
    (tagName) => allTagRules.some((rule) => rule.tagName === tagName),
    [allTagRules],
  );

  useEffect(() => {
    const isValid = selectedTagKeys.some(
      (key) =>
        (1 - relatedInfo[key]?.counter / Object.keys(currentDataGroup).length) *
        100,
    );

    setAllSelectedTagsValid(isValid);
  }, [selectedTagKeys]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const folderKeys = Object.keys(folders);
      setFilteredFolderKeys(
        folderKeys.filter(
          (folderKey) =>
            folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
            folders[folderKey].some((file) =>
              file.toLowerCase().includes(searchText.toLowerCase()),
            ),
        ),
      );
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, folders]);

  useEffect(() => {
    if (isTimeSelectionModalOpen) {
      const initialCheckedItems = {};
      selectedCatalogItems.forEach((item) => {
        initialCheckedItems[item] = { isChecked: true };
      });
      setCheckedItems(initialCheckedItems);
    }
  }, [isTimeSelectionModalOpen, selectedCatalogItems, setCheckedItems]);

  const runSelectedTags = useCallback(
    async (
      rerun = false,
      label = null,
      selectedTagRules = null,
      smartSelect = false,
      coreTags = [],
    ) => {
      setIsGloballyExpanded(true);
      const taskId = `runAllTags-${Date.now()}`;
      const entries = [];
      const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
      const tagTimeStamp = new Date().toISOString();
      const tagsToRun = rerun && label ? [label] : selectedTagKeys;

      for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict ||
        {}) {
        if (tagsToRun.length === 0 || tagsToRun.includes(tagKey)) {
          availableTagsCopy.llm.tagger_params.tag_dict[tagKey].tagged_at =
            tagTimeStamp;
        } else {
          delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
        }
      }
      const dataSnapShot = { ...currentDataGroup };

      const allTags = Object.keys(
        availableTags.llm.tagger_params.tag_dict,
      ).filter((tagKey) => {
        return tagsToRun.length === 0 || tagsToRun.includes(tagKey);
      });

      setProcessingTags((prev) => [
        ...prev,
        ...allTags.map((tag) => ({ label: tag })),
      ]);

      const selectedFiles = Array.from(selectedCatalogItems);

      for (const file_name of Object.keys(dataSnapShot)) {
        if (selectedFiles.length === 0 || selectedFiles.includes(file_name)) {
          const catalogItem = dataSnapShot[file_name];
          const sendChunkObject = {
            data_store: JSON.stringify({
              ...preferences.webapp_profile.DATA_STORES[
                catalogItem.data_store_name
                  ? catalogItem.data_store_name
                  : catalogItem.storage_type
              ],
              path: `${catalogItem.file_directory}/${file_name}`,
            }),
            file_catalog_entry: JSON.stringify({ [file_name]: {} }),
            catalog_name: usedCatalog,
            quarantine_name: preferences.system.QUARANTINECATALOG,
            check_sensitivity: false,
            smart_selection: smartSelect,
          };

          entries.push(sendChunkObject);
        }
      }

      setRunningTasks((tasks) => [
        ...tasks,
        {
          id: taskId,
          process: "Tagging",
          description: "Running " + selectedTagKeys.length + " tags",
          completed: 0,
        },
      ]);

      try {
        setProcessingTagTaskProgress(0);
        const creds = (await Auth.currentAuthenticatedUser()).username;
        const res = await sendRequest(
          {
            entries,
            tagger_list: JSON.stringify(availableTagsCopy),
            [preferences.system.API_USERNAME_KEYWORD]: creds,
            preferences: JSON.stringify(preferences),
            tag_rules: selectedTagRules,
            smart_selection: smartSelect,
            core_tags: coreTags,
          },
          rerun ? ENDPOINTS["rerun_tag"] : ENDPOINTS["create_catalog_in_bulk"],
        );
        const { task_id } = await res.json();

        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === taskId);
          if (updatedTask) {
            updatedTask.id = task_id;
          }
          return [...tasks];
        });
        setDocumentTaggingTask(task_id);
        const automaticUpdateCatalogIntervalId = setInterval(() => {
          getCatalogAndUpdateCatalog(usedCatalog, true, true);
        }, 20000);

        await waitTaskDone(task_id, creds, undefined, ({ completed }) => {
          setRunningTasks((tasks) => {
            const updatedTask = tasks.find((task) => task.id === task_id);
            if (updatedTask) {
              updatedTask.completed = completed;
            }
            return [...tasks];
          });
          setProcessingTagTaskProgress(completed);
        });

        clearInterval(automaticUpdateCatalogIntervalId);
        await getCatalogAndUpdateCatalog(usedCatalog, true, true);
        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === task_id);
          if (updatedTask) {
            updatedTask.completed = 1;
          }
          return [...tasks];
        });

        setProcessingTags((prev) =>
          prev.filter(({ label }) => !allTags.includes(label)),
        );
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          allTags.forEach((tag) => {
            newMap.delete(tag);
          });
          return newMap;
        });
        autoStandardizeTagsWithoutAvailableValues();
        setDocumentTaggingTask(null);
      } catch (error) {
        console.error("Error running all tags:", error);
        toast.error({
          title: "Error",
          description: "There was an issue processing the tags.",
        });

        setProcessingTags((prev) =>
          prev.filter(({ label }) => !allTags.includes(label)),
        );
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          allTags.forEach((tag) => {
            newMap.delete(tag);
          });
          return newMap;
        });
      } finally {
        setIsSmartSelectEnabled(false);
      }
    },
    [
      availableTags,
      currentDataGroup,
      setProcessingTags,
      setRunningTasks,
      selectedTagKeys,
      preferences.webapp_profile.DATA_STORES,
      preferences.system.QUARANTINECATALOG,
      preferences.system.API_USERNAME_KEYWORD,
      usedCatalog,
      setProcessingTagTasks,
    ],
  );

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  const abortAllTags = async () => {
    const allTags = Object.keys(availableTags.llm.tagger_params.tag_dict);

    await abortTask(
      documentTaggingTask,
      (await Auth.currentAuthenticatedUser()).username,
      "Failed to stop the tagging process.",
      "The tagging process has been successfully stopped.",
    );

    setRunningTasks((tasks) =>
      tasks.filter((t) => t.id !== documentTaggingTask),
    );
    setProcessingTags((prev) =>
      prev.filter(({ label }) => !allTags.includes(label)),
    );
    setProcessingTagTasks((prev) => {
      const newMap = new Map(prev);
      allTags.forEach((tag) => {
        newMap.delete(tag);
      });
      return newMap;
    });
  };

  if (catalogGetsRenamed) {
    return (
      <div
        className={`h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden justify-center items-center ${isExpanded ? "w-full" : "w-20"}`}
      >
        <div
          className={`animate-spin rounded-full border-b-2 border-primary ${isExpanded ? "h-32 w-32" : "h-16 w-16"}`}
        ></div>
      </div>
    );
  }

  return (
    <div
      className={`h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden transition-all  ${
        isExpanded ? "w-full" : "w-20"
      }`}
    >
      <div
        className="p-2 px-4 dark:bg-zinc-600 dark:text-white bg-gray-200 flex justify-between items-center cursor-pointer"
        onClick={() => {
          if (!isExpanded) {
            setIsExpanded(true);
          }
        }}
      >
        {isExpanded ? (
          <header className="text-sm font-bold text-grey dark:text-gray-200">
            Available Tags
          </header>
        ) : (
          <div className="w-full flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        )}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          {isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
          ) : (
            <div
              className="flex flex-col items-center whitespace-nowrap text-xs"
              title={`Expand to view ${
                Object.keys(availableTags.llm.tagger_params.tag_dict).length
              } Tags`}
            >
              <p className="text-sm font-bold">
                {Object.keys(availableTags.llm.tagger_params.tag_dict).length}
              </p>
              <p className="text-xs">Tags</p>
            </div>
          )}
        </button>
      </div>

      {isExpanded && (
        <>
          {isTimeSelectionModalOpen && (
            <div className="Modal z-50 backdrop-blur-md">
              <div className="ModalContent">
                <ScheduleSettingComponent
                  folders={folders}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  setShowFilePreview={setShowFilePreview}
                  filteredFolderKeys={filteredFolderKeys}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  setFolders={setFolders}
                  setTimeSelectionModalOpen={setTimeSelectionModalOpen}
                  selectedTagKeys={selectedTagKeys}
                  isSmartSelectEnabled={isSmartSelectEnabled}
                  setIsSmartSelectEnabled={setIsSmartSelectEnabled}
                  availableTags={availableTags}
                  currentDataGroup={currentDataGroup}
                  usedCatalog={usedCatalog}
                />
              </div>
            </div>
          )}
          <div className="p-4 space-y-4">
            <div className="flex items-center space-x-4">
              <div className="relative w-full max-w-xs">
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1 whitespace-nowrap">
                  Search Tags
                </label>
                <input
                  type="text"
                  placeholder="Enter tag name"
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  className="w-full text-gray-700 border border-gray-300 rounded-md focus:border-none"
                  style={{
                    padding: "2px 8px 2px 8px",
                    height: "28px",
                    minHeight: "28px",
                    fontSize: "0.6375rem",
                    boxShadow: "none",
                    borderRadius: "4px",
                    border: "1px solid #aaa",
                    overflow: "hidden",
                    outline: "none",
                  }}
                />
              </div>
              <div className="w-full max-w-xs">
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1 whitespace-nowrap">
                  Filter by Group
                </label>
                <Select
                  options={allGroups}
                  placeholder="Select group"
                  isSearchable={true}
                  value={selectedGroup}
                  isClearable={true}
                  onChange={(e) => setSelectedGroup(e)}
                  styles={customStyles}
                />
              </div>
              <div className="w-full max-w-xs">
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Sort by
                </label>
                <Select
                  options={sortOptions}
                  isClearable={false}
                  placeholder="Select sorting option"
                  isSearchable={true}
                  value={sortOptions.find(
                    (option) =>
                      option.value ===
                      `${sortMethod}-${sortAscending ? "asc" : "desc"}`,
                  )}
                  onChange={(e) => {
                    const [newSortMethod, newSortOrder] = e.value.split("-");
                    setSortMethod(newSortMethod);
                    setSortAscending(newSortOrder === "asc");
                  }}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center pl-4 pr-4 pb-2">
            <PermissionGuard scope="catalogs" level="canEdit">
              <button
                className={`border  ${selectedTagKeys.length > 0 ? "text-white bg-primary hover:bg-white hover:text-primary" : "text-primary border-primary bg-white hover:bg-primary hover:text-white"} rounded-md px-3 py-1 text-sm  transition-colors`}
                onClick={() => {
                  setSelectedTagKeys(
                    selectedTagKeys.length
                      ? []
                      : Object.keys(
                          availableTags?.llm?.tagger_params?.tag_dict || {},
                        ),
                  );
                }}
              >
                {selectedTagKeys.length > 0 ? "Deselect all" : "Select all"}
              </button>
            </PermissionGuard>
            <div className="flex flex-row gap-3">
              <PermissionGuard scope="tags" level="canEdit">
                <button
                  className="px-3 py-1 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-150 ease-in-out"
                  onClick={(e) =>
                    clearLabels(e, selectedTagKeys).then(
                      () => setSelectedTagKeys([]),
                      getCatalogAndUpdateCatalog(usedCatalog),
                    )
                  }
                  disabled={selectedTagKeys.length === 0}
                  title="Clear selected tags"
                >
                  <p>Clear all</p>
                </button>
              </PermissionGuard>
              <PermissionGuard scope="tags" level="canEdit">
                <button
                  className="px-3 py-1 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-150 ease-in-out"
                  onClick={(e) =>
                    deleteAllLabels(e, selectedTagKeys).then(() =>
                      setSelectedTagKeys([]),
                    )
                  }
                  disabled={selectedTagKeys.length === 0}
                  title="Delete selected tags"
                >
                  Delete
                </button>
              </PermissionGuard>
            </div>
          </div>
          <div className="flex flex-col overflow-y-auto overflow-x-hidden h-full hide-scrollbar">
            {tagsShown
              .sort((tagAKey, tagBKey) => {
                const tagA = tagDict[tagAKey];
                const tagB = tagDict[tagBKey];

                if (!tagA || !tagB) {
                  return 0;
                }

                if (sortMethod === "date") {
                  const dateA = tagA.updated_at
                    ? new Date(tagA.updated_at)
                    : new Date("1976/01/01");
                  const dateB = tagB.updated_at
                    ? new Date(tagB.updated_at)
                    : new Date("1976/01/01");
                  return sortAscending
                    ? dateA.getTime() - dateB.getTime()
                    : dateB.getTime() - dateA.getTime();
                } else if (sortMethod === "completion") {
                  const percentageA = completionPercentages[tagAKey];
                  const percentageB = completionPercentages[tagBKey];
                  return sortAscending
                    ? percentageA - percentageB
                    : percentageB - percentageA;
                } else if (sortMethod === "rule") {
                  const isRuleTagA = isRuleTag(tagAKey);
                  const isRuleTagB = isRuleTag(tagBKey);
                  return sortAscending
                    ? isRuleTagA - isRuleTagB
                    : isRuleTagB - isRuleTagA;
                } else {
                  return 0;
                }
              })
              .map((key) => {
                return (
                  <div
                    data-testid="tagDefinition"
                    className="relative cursor-pointer pt-12 pb-2 bg-white mt-1 mx-1 rounded-md shadow-md border-2"
                    onClick={() => {
                      if (!selectedTagKeys.includes(key)) {
                        setSelectedTagKeys((prev) => [...prev, key]);
                      } else {
                        setSelectedTagKeys((prev) =>
                          prev.filter((_key) => _key !== key),
                        );
                      }
                    }}
                  >
                    {selectedTagKeys.includes(key) && (
                      <div className="inset-0 bg-green-600 bg-opacity-10 absolute z-10 pointer-events-none rounded-sm"></div>
                    )}
                    <div className="absolute top-0 left-0 px-2 flex justify-between p-2 rounded-md">
                      <PermissionGuard scope="catalogs" level="canEdit">
                        <input
                          type="checkbox"
                          data-testid="tagSelectionCheckbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTagKeys((prev) => [...prev, key]);
                            } else {
                              setSelectedTagKeys((prev) =>
                                prev.filter((_key) => _key !== key),
                              );
                            }
                          }}
                          checked={selectedTagKeys.includes(key)}
                          onClick={(e) => e.stopPropagation()}
                          className="appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
                          id={`checkbox-${key}`}
                        />
                        <label
                          htmlFor={`checkbox-${key}`}
                          className="absolute inset-0 flex items-center justify-center pointer-events-none text-white"
                        >
                          <svg
                            className="w-3 h-3 text-white hidden peer-checked:block"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </label>
                      </PermissionGuard>
                    </div>
                    <TagFilter
                      label={key}
                      categoryKey={key}
                      showScreen={showScreen}
                      isBeingDeleted={tagsToBeDeleted.includes(key)}
                      selectedTagKeys={selectedTagKeys}
                      setSelectedTagKeys={setSelectedTagKeys}
                      runSelectedTags={runSelectedTags}
                      tagRules={allTagRules}
                      completionPercentage={completionPercentages[key]}
                    ></TagFilter>
                  </div>
                );
              })}
          </div>
          <PermissionGuard scope="catalogs" level="canEdit">
            <div className="bg-slate-200 dark:bg-zinc-600 flex flex-col w-full">
              <div className="flex flex-row w-full justify-between">
                {selectedTagKeys.length > 0 && (
                  <div className="bg-slate-100 border w-full justify-between flex flex-row items-center">
                    {processingTags.length > 0 ||
                    documentTaggingTask !== null ? (
                      <button
                        data-testid="abortAll"
                        className="py-3 px-6 flex flex-row items-center bg-slate-100 justify-end"
                        onClick={abortAllTags}
                      >
                        <p className="text-sm bg-red-400 text-white p-2 rounded-md font-bold">
                          Abort All
                        </p>
                      </button>
                    ) : (
                      <div className="w-full justify-end flex">
                        <UpwardsDropdown
                          isOpen={isTagOpen}
                          setIsOpen={setIsTagOpen}
                          setShowTagRulesModal={setShowTagRulesModal}
                          setTimeSelectionModalOpen={setTimeSelectionModalOpen}
                          selectedTagKeys={selectedTagKeys}
                          filteredTagRules={filteredTagRules}
                          runSelectedTags={runSelectedTags}
                          setIsSmartSelectEnabled={setIsSmartSelectEnabled}
                          isSmartSelectEnabled={isSmartSelectEnabled}
                          currentDataGroup={currentDataGroup}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </PermissionGuard>
        </>
      )}
      {showTagRulesModal && (
        <TagRuleFilter
          rules={filteredTagRules}
          onSubmit={(selectedRules) => {
            setShowTagRulesModal(false);
            runSelectedTags(false, null, selectedRules);
          }}
          onClose={() => {
            setShowTagRulesModal(false);
          }}
        />
      )}
    </div>
  );
};

export default TagDefinitions;

const UpwardsDropdown = ({
  isSmartSelectEnabled,
  setIsSmartSelectEnabled,
  runSelectedTags,
  ...props
}) => {
  const dropdownRef = useRef(null);
  const [showCoreTagsModal, setShowCoreTagsModal] = useState(false);
  const { setTaggingRunning } = useContext(DataContext);
  const checkSelectedTagsAgainstRules = useCallback(() => {
    return props.selectedTagKeys.some((tagKey) =>
      props.filteredTagRules.some((rule) => rule.tagName === tagKey),
    );
  }, [props.selectedTagKeys, props.filteredTagRules]);

  const handleAfterCoreTagsModal = (coreTags) => {
    setShowCoreTagsModal(false);
    if (checkSelectedTagsAgainstRules()) {
      props.setShowTagRulesModal(true);
    } else {
      runSelectedTags(false, null, null, isSmartSelectEnabled, coreTags);
    }
  };

  const handleRunNowClick = () => {
    if (isSmartSelectEnabled) {
      setShowCoreTagsModal(true);
    } else if (checkSelectedTagsAgainstRules()) {
      props.setShowTagRulesModal(true);
    } else {
      runSelectedTags(false, null, null, isSmartSelectEnabled);
    }
    props.setIsOpen(false);
    setTaggingRunning(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        props.setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSmartSelectChange = (event) => {
    const newValue = event.target.checked;
    setIsSmartSelectEnabled(newValue);
  };

  return (
    <div
      className="relative flex justify-between bg-white w-full p-2 gap-4 items-center"
      ref={dropdownRef}
    >
      <TagSelectionIndicator
        totalDatasets={Object.keys(props.currentDataGroup).length}
        setIsOpen={props.setIsOpen}
        isOpen={props.isOpen}
      />
      {showCoreTagsModal && (
        <CoreTagsModal
          isOpen={showCoreTagsModal}
          onClose={() => setShowCoreTagsModal(false)}
          selectedTags={props.selectedTagKeys}
          onConfirm={handleAfterCoreTagsModal}
        />
      )}
      {props.selectedTagKeys.length > 0 && (
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium text-gray-700">
            Smart-select
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer checked:text-primary"
              checked={isSmartSelectEnabled}
              onChange={handleSmartSelectChange}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
          </label>
          <div className="relative group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 cursor-help"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2 w-48">
              Smart-select pre-filters tags to run only relevant tags for each
              document, improving efficiency.
            </div>
          </div>
        </div>
      )}
      {props.isOpen && (
        <div className="text-sm absolute bottom-full left-0 mb-1 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 ease-in-out z-50 w-48 ml-4">
          <button
            className="w-full py-3 px-4 text-left hover:bg-buttonGrey hover:bg-opacity-10 hover:text-black transition-colors duration-200 flex items-center"
            onClick={handleRunNowClick}
            data-testid="runItNowSelectedTagsButton"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Run it now
          </button>

          <button
            className="w-full py-2 px-4 text-left text-gray-700 hover:bg-buttonGrey hover:bg-opacity-10 hover:text-black transition-colors duration-200 flex items-center"
            onClick={() => {
              props.setTimeSelectionModalOpen(true);
              props.setIsOpen(false);
            }}
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            Schedule
          </button>
        </div>
      )}
    </div>
  );
};

const TagSelectionIndicator = ({ totalDatasets, setIsOpen, isOpen }) => {
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const [selectedTagKeys] = useAtom(selectedTagKeysAtom);
  const [showFullText, setShowFullText] = useState(false);

  const selectedDatasets = selectedCatalogItems.size || totalDatasets;
  const datasetsText = selectedCatalogItems.size ? selectedDatasets : "all";
  const fullText = `${datasetsText} dataset${datasetsText !== 1 ? "s" : ""} with ${selectedTagKeys.length} tag${selectedTagKeys.length !== 1 ? "s" : ""}`;

  return (
    <div className="relative inline-block">
      {showFullText && (
        <div className="absolute bottom-full left-0 mb-1 whitespace-nowrap bg-gray-800 text-white text-xs rounded py-1 px-2">
          {fullText}
        </div>
      )}
      <button
        className="flex items-center justify-between py-2 px-4 bg-gradient-to-r from-primary to-secondary text-white text-md font-semibold rounded-md shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
        data-testid="runSelectedTagsButton"
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setShowFullText(true)}
        onMouseLeave={() => setShowFullText(false)}
      >
        <span className="mr-2 text-base">Tag</span>
        <div className="flex items-center space-x-2 text-xs bg-white bg-opacity-20 rounded px-2 py-1">
          <span>{datasetsText}</span>
          <span>|</span>
          <span>{selectedTagKeys.length}</span>
        </div>
        <svg
          className="w-4 h-4 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </div>
  );
};
